<template>
  <div>
    <b-alert :show="showAlert" variant="danger">
      <span>{{ alertMsg }}</span>
    </b-alert>
    <div class="row">
      <div class="col-md-9">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <DualListBox
              :source="lessors"
              :destination="selectedLessors"
              label="name"
              @onChangeList="onChangeList"
            />
            <div class="pt-4 px-4">
              <button
                class="btn btn-primary btn-block font-weight-bold"
                @click="generate"
              >
                Generate
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <DatePicker
              ref="picker"
              v-model="range"
              mode="dateTime"
              :attributes="attributes"
              :masks="masks"
              locale="hr"
              is-range
              is24hr
              is-expanded
              :columns="$screens({ default: 1, lg: 1 })"
              :max-date="new Date()"
            ></DatePicker>
            <button
              @click="last7Days"
              class="btn btn-light-primary btn-block font-weight-bold mt-2"
            >
              Last 7 days
            </button>
            <button
              @click="last30Days"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last 30 days
            </button>
            <button
              @click="thisMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              This month
            </button>
            <button
              @click="lastMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last month
            </button>
            <button
              @click="reset"
              class="btn btn-light-danger btn-block font-weight-bold mt-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <b-spinner v-if="showSpinner" variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
    <div v-if="isGenerated" class="row mb-6">
      <div class="col-md-12">
        <TransactionsTraffic v-if="isGenerated" :traffic="generatedResults" :range="range" />
      </div>
    </div>
    <div v-if="isGenerated && currentActiveModuleComponents?.reports.reportWeek" class="row mb-6">
      <div class="col-md-6">
        <WeekTraffic v-if="isGenerated" :traffic="generatedResults" :range="range" />
      </div>
      <div class="col-md-6">
        <MonthTraffic v-if="isGenerated" :traffic="generatedResults" :range="range" />
      </div>
    </div>
    <div v-if="isGenerated && currentActiveModuleComponents?.reports.reportYear" class="row mb-6">
      <div class="col-md-6">
        <YearTraffic v-if="isGenerated" :traffic="generatedResults" :range="range" />
      </div>
      <div class="col-md-6">
        <WeekDaysTraffic v-if="isGenerated" :traffic="generatedResults" :range="range" />
      </div>
    </div>
    <div v-if="isGenerated && currentActiveModuleComponents?.reports.AverageByHoursOfTheDay" class="row mb-6">
      <div class="col-md-12">
        <HoursTraffic v-if="isGenerated" :traffic="generatedResults" :range="range" />
      </div>
    </div>
    <div v-if="isGenerated && currentActiveModuleComponents?.reports.AverageByHoursOfTheDay" class="row mb-6">
      <div class="col-md-12">
        <DaysTraffic
          v-if="isGenerated"
          :type="'lessor'"
          :traffic="generatedResults"
          :range="range"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import format from 'date-fns/format'
import DualListBox from '@/view/content/components/dual-list-box/DualListBox'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { dateFilter } from 'vue-date-fns'
import DaysTraffic from '@/view/pages/token-report/_components/DaysTraffic'
import MonthTraffic from '@/view/pages/token-report/_components/MonthTraffic'
import WeekTraffic from '@/view/pages/token-report/_components/WeekTraffic'
import HoursTraffic from '@/view/pages/token-report/_components/HoursTraffic'
import WeekDaysTraffic from '@/view/pages/token-report/_components/WeekDaysTraffic'
import YearTraffic from '@/view/pages/token-report/_components/YearTraffic'
import TransactionsTraffic from '@/view/pages/token-report/_components/TransactionsTraffic'
import add from 'date-fns/add'
import startOfMonth from 'date-fns/startOfMonth'
import getMonth from 'date-fns/getMonth'
import addMonths from 'date-fns/addMonths'
import getYear from 'date-fns/getYear'
import endOfMonth from 'date-fns/endOfMonth'
import addDays from 'date-fns/addDays'

export default {
  name: 'LessorsFinanceReport',
  filters: {
    date: dateFilter,
  },
  components: {
    DatePicker,
    DualListBox,
    WeekDaysTraffic,
    DaysTraffic,
    HoursTraffic,
    WeekTraffic,
    MonthTraffic,
    YearTraffic,
    TransactionsTraffic,
  },
  data() {
    return {
      lessors: [],
      selectedLessors: [],
      generatedResults: [],
      totalItems: null,
      range: {
        start: add(new Date(), { days: -1 }).setHours(24, 0, 0),
        end: new Date().setHours(23, 59, 59),
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      showAlert: false,
      alertMsg: null,
      base: 0,
      tax: 0,
      total: 0,
      isGenerated: false,
      showSpinner: false,
      isLoaded: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentActiveModuleComponents',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Reports by Lessors', route: '/finance-report/lessor' },
    ])

    ApiService.get('lessors', '?pagination=false').then(({ data }) => {
      this.totalItems = data['hydra:member'].length
      this.lessors = [...data['hydra:member']]
      this.isLoaded = true
    })
  },
  methods: {
    generate() {
      if (
        this.selectedLessors.length === 0 ||
        this.range.start === null ||
        this.range.end === null
      ) {
        this.alertMsg = 'Please select lessor/s & pick range'
        this.showAlert = true
        return
      }

      this.showSpinner = true
      let num = 0
      const after = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const before = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      this.selectedLessors.forEach((lessor) => {
        ApiService.get(
          'transactions',
          `?lessor=${lessor['@id']}&company=${this.currentUserCompany}&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`
        ).then(({ data }) => {
          data['hydra:member'].forEach(
            ({
              imei,
              deviceMeta,
              amount,
              paymentType,
              createdAt,
              locationMeta,
              isSaldoReducer
            }) => {
              if(paymentType === 'pay_token' && !isSaldoReducer) {
                  this.generatedResults.push({
                  imei: imei,
                  deviceName: deviceMeta.name,
                  location: locationMeta.title ? locationMeta.title : '',
                  lessor: locationMeta.hasOwnProperty('lessor')
                    ? locationMeta.lessor
                    : '',
                  address: locationMeta.address ? locationMeta.address : '',
                  tags: locationMeta.tags ? locationMeta.tags : [],
                  amount: amount,
                  paymentType: paymentType,
                  createdAt: format(new Date(createdAt), 'dd.MM.yyyy @ HH:mm:ss'),
                })
              }
            }
          )
          num++
          if (num === this.selectedLessors.length) {
            this.isGenerated = true
            this.showSpinner = false
          }
        })
      })
    },
    last7Days() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -7 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    last30Days() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -30 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    thisMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })
      const today = new Date()

      this.$refs.picker.updateValue({
        start: startOfMonth(today),
        end: today,
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 2,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    lastMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    reset() {
      this.$refs.picker.updateValue({ start: null, end: null })
    },
    resetAll() {
      this.generatedResults = []
      this.totalItems = null
      this.range.start = null
      this.range.end = null
      this.base = 0
      this.tax = 0
      this.total = 0
      this.isGenerated = false
      this.showSpinner = false
      this.isLoaded = false
    },
    onChangeList: function ({ source, destination }) {
      this.lessors = source
      this.selectedLessors = destination
    },
    setStartEndDate() {
      let a = addDays(this.range.start, -1)
      a.setHours(24, 0, 0)
      this.range.start = a

      let b = addDays(this.range.end, 0)
      b.setHours(23, 59, 59)
      this.range.end = b

      this.$refs.picker.updateValue({
        start: this.range.start,
        end: this.range.end,
      })
    },
  },
}
</script>
